import * as React from "react"
import Layout from "../components/layout"
import { navigate } from "gatsby-link"
const pageTitle = "Contact"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactPage() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
    // console.log(state)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error))
  }

  return (
    <Layout pageTitle={pageTitle}>
      <header className="page-header">
        <h1>{pageTitle}</h1>
      </header>
      <section className="container logo">
        <form
          name="contact"
          method="post"
          action="/thanks/"
          data-netlify="true"
          // data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <p>
            Thanks for stopping by. If you'd like to get in touch with us, fill
            out our contact form and we'll get back to you as soon as we can.
          </p>
          {/* <p hidden>
            <label>
              Don’t fill this out:
              <input name="bot-field" onChange={handleChange} required />
            </label>
          </p> */}
          <p>
            <label>
              <span>Name:</span>{" "}
              <input type="text" name="name" onChange={handleChange} required />
            </label>
          </p>
          <p>
            <label>
              <span>Email:</span>{" "}
              <input
                type="email"
                name="email"
                onChange={handleChange}
                required
              />
            </label>
          </p>
          <p>
            <label>
              <span>Message: </span>
              <textarea name="message" onChange={handleChange}></textarea>
            </label>
          </p>
          <p className="button-area">
            <button type="submit">Send</button>
          </p>
        </form>
      </section>
    </Layout>
  )
}
